import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class CloudAccounting extends React.Component {
	render() {
		const siteTitle = 'Cloud Accounting'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Cloud Accounting</h3>
								<p>
									Cloud accounting allows for real-time reporting of your
									business performance without the burden of having to install
									and maintain software.
									<br />
									<br />
									It allows small-business owners flexibility in how they want
									to run their business. Cloud based software allows access from
									anywhere where there is an internet connection, meaning that
									your employees can have the necessary tools at their
									fingertips to invoice customers on site and access customer
									accounts.
									<br />
									<br />
									Most cloud based accounting systems can also integrate with
									other cloud-based software giving business owners greater
									control and flexibility about how you operate your business.
									<br />
									<br />
									WSC Group can work with you to utilise cloud accounting
									software within your small business together with setting up
									internal controls to ensure that you are saving time and
									money.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CloudAccounting

export const CloudAccountingPageQuery = graphql`
	query CloudAccountingPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
